<template>
    <div>
        <template v-if="updateMode && !listMode">
            <v-btn :loading="loading.url" class="ml-3" small @click.stop="printSalesOrder()">
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.salesOrder') }}</span>
            </v-btn>
            <v-btn :loading="loading.urlProformaInvoice" small class="ml-3" @click.stop="printSalesOrder(true)">
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.proformaInvoice') }}</span>
            </v-btn>
        </template>
        <template v-if="updateMode && listMode">
            <v-list-item class="font-sm" @click.stop="printSalesOrder()">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.url"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.salesOrder') }}</span>
            </v-list-item>
            <v-list-item class="font-sm" @click.stop="printSalesOrder(true)">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.urlProformaInvoice"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.proformaInvoice') }}</span>
            </v-list-item>
        </template>
    </div>
</template>

<script>

import {mapFields} from "vuex-map-fields";
import {api} from "Api";
// import {log, makeId} from "Helpers/helpers";

export default {
    name: "PrintSalesOrderButtons",
    props: ['salesOrderId','updateMode','listMode','version'],
    data() {
        return {
            loading: {
                url: false,
                urlProformaInvoice: false
            }
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        getPrintPdfUrl(pi = false) {
            return new Promise((resolve, reject) => {
                let urlComponent = "/print-url/so/"
                if(pi) urlComponent = "/print-url/so-pi/"
                api
                    .get(urlComponent + this.salesOrderId)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        printSalesOrder(pi = false) {
            if(pi){
                this.loading.urlProformaInvoice = true
            } else {
                this.loading.url = true
            }
            this.getPrintPdfUrl(pi)
                .then(response => {
                    if(response.data.status == 'success') {
                        let tab = window.open(response.data.url, '_blank');
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                    }
                    if(this.loading.urlProformaInvoice) this.loading.urlProformaInvoice = false;
                    if(this.loading.url) this.loading.url = false;
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.pdfError'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        })
                    if(this.loading.urlProformaInvoice) this.loading.urlProformaInvoice = false;
                    if(this.loading.url) this.loading.url = false;
                })
        }
    }
}
</script>

<style scoped>

</style>